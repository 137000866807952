<template>
    <Detail />
</template>

<script>
import Detail from "./Detail.vue"
import store from "./store/index"
export default {
    components: {
        Detail
    },
    created() {
        if(!this.$store.hasModule('products'))
            this.$store.registerModule("products", store)
    }
}
</script>