export default () => ({
    goodsList: {},
    goodsPage: {},
    goodsSearch: '',
    goodsEmpty: {},
    goodsCategory: null,
    ordering: 'popularity',
    detail: null,
    historyGoods: [],
    gridType: [],
    activeGridType: null
})